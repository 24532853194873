import React from 'react';
import '../App.css';
import './HeroSection.css';
import './contactus.css';
import { Link } from 'react-router-dom';
import emailjs from 'emailjs-com';
import { motion } from 'framer-motion'

function Contactus() {

  function sendEmail(e) {
    e.preventDefault();

    emailjs.sendForm(process.env.REACT_APP_YOUR_SERVICE_ID, process.env.REACT_APP_YOUR_TEMPLATE_ID, e.target, process.env.REACT_APP_YOUR_USER_ID)
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });

    alert('We have received your message!');
  }

  return (
  <div className="display-contact">
    <div className='contactus'>
      <h1>Contact Us</h1>
      <br></br>
      <p>Avaime Inc.</p>
      <p>Phone: (415) 439-0537</p>
      <p>Email: info@avaime.com</p>
      <br></br>
      <br></br>
      <div class='social-icons-contact'>
            <Link
              class='social-icon-link-contact facebook'
              to= {{ pathname: "https://www.facebook.com" }}
              target='_blank'
              aria-label='Facebook'
            >
              <i class='fab fa-facebook-f' />
            </Link>
            <Link
              class='social-icon-link-contact instagram'
              to= {{ pathname: "https://www.instagram.com" }}
              target='_blank'
              aria-label='Instagram'
            >
              <i class='fab fa-instagram' />
            </Link>
            <Link
              class='social-icon-link-contact youtube'
              to= {{ pathname: "https://www.youtube.com" }}
              target='_blank'
              aria-label='Youtube'
            >
              <i class='fab fa-youtube' />
            </Link>
            <Link
              class='social-icon-link-contact twitter'
              to= {{ pathname: "https://www.twitter.com" }}
              target='_blank'
              aria-label='Twitter'
            >
              <i class='fab fa-twitter' />
            </Link>
            <Link
              class='social-icon-link-contact twitter'
              to= {{ pathname: "https://www.linkedin.com" }}
              target='_blank'
              aria-label='LinkedIn'
            >
              <i class='fab fa-linkedin' />
            </Link>
          </div>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
    </div>
      <form onSubmit={sendEmail}>
        <h1>Leave us a message!</h1>
        <input
        type='text'
        name='name'
        placeholder='Name'
        required
        >
        </input>
        <input
        type='email'
        name='email'
        placeholder='Email'
        required
        ></input>
        <textarea
        type='text'
        name='message'
        placeholder='Message'
        ></textarea>
        <motion.button type='submit' whileHover={{ scale: 1.03}}>Submit</motion.button>
      </form>
  </div>
  );
}

export default Contactus;

import React from 'react';
import '../../App.css';
import Footer from '../Footer';
import Contactus from '../contactus';

export default function ContactUs() {
  return (
    <>
    <div className='sign-up'>
    </div>
    <Contactus></Contactus>
    <Footer />
    </> 
  );
    
}

const jmeter = [
    {
      id: 1,
      title: 'Section 1: Introduction to Performance testing',
      info:
        '1. Introduction to software application testing \n2. Introduction to performance testing \n3. Types of performance testing \n4. Metrics used to determine the performance.',
    },
    {
      id: 2,
      title: 'Section 2: Introduction to Software Architecture',
      info:
        '1. Architecture and its importance for Performance testing \n2. Different types of architectures',
    },
    {
      id: 3,
      title: 'Section 3: Requirements gathering',
      info:
        '1. What are Nonfunctional Requirements (NFRs) \n2. Littles law and Workload modelling',
    },
    {
      id: 4,
      title: 'Section 4: Performance testing lifecycle',
      info:
        '1. Performance testing lifecycle \n2. Performance testing vs Performance engineering \n3. Performance test planning with a real project example',
    },
    {
      id: 5,
      title: 'Section 5: Introduction to Jmeter',
      info:
        '1. Overview of Jmeter and its features \n2. Installation of Jmeter \n3. Installation of Jmeter plugins \n4. Sample test script view and execution',
    },
    {
        id: 6,
        title: 'Section 6: JMeter test plan elements',
        info:
          '1. Introduction to test plan \n2. Overview of the elements of a test plan \n3. Record and playback of test script using Jmeter',
    },
    {
        id: 7,
        title: 'Section 7: Utilization of test plan elements to build test scripts - Part 1',
        info:
          '1. Elements of test plan - Thread Group \n2. Elements of test plan - Samplers (Http requests) \n3. Elements of test plan  - Listeners',
    },
    {
        id: 8,
        title: 'Section 8: Utilization of test plan elements to build test scripts - Part 2',
        info:
          '1. Elements of test plan - Controllers \n2. Elements of test plan - Logic Controllers \n3. Elements of test plan - Timers (Pacing vs Think time) \n4. Elements of test plan - Assertions',
    },
    {
        id: 9,
        title: 'Section 9: Utilization of test plan elements to build test scripts - Part 3',
        info:
          '1. Elements of test plan  - Configuration Elements \n2. Elements of test plan  - Pre-processor Elements \n3. Correlation using pre-processor elements in Jmeter \n4. Elements of test plan  - Post-processor Elements',
    },
    {
        id: 10,
        title: 'Section 10: Functions and Variables in Jmeter',
        info:
          '1. Introduction to functions and variables \n2. Parameterization using variables in Jmeter \n3. Utilizing functions to build test script \n4. Utilizing variables to build test script \n5. JMeter function helper',
    },
    {
        id: 11,
        title: 'Section 11: Execution of test plan',
        info:
          '1. Addition and removal of test elements \n2. Running a test plan - GUI and Non-Gui mode \n3. View test results as realtime output \n4. Saving test results to a .jmx file',
    },
]
export default jmeter
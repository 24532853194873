import React from 'react';
import '../../App.css';
import ServicesTitle from '../ServicesTitle';
import Cards from '../Cards';
import Footer from '../Footer';

export default function Services() {
  return (
    <>
      <ServicesTitle />
      <Cards />
      <Footer />
    </>
  );
}

import React from 'react';
import Navbar from './components/Navbar';
import './App.css';
import Home from './components/pages/Home';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import CareersPage from './components/pages/CareersPage';
import Blog from './components/pages/Blog';
import ContactUs from './components/pages/Contactus'
import Services from './components/pages/Services';
import ScrollToTop from './components/ScrollToTop';
import JMeter from './components/pages/JMTRTraining';
import LoadR from './components/pages/LRTraining';

function App() {
  return (
    <>
      <Router>
        <ScrollToTop />
        <Navbar />
        <Switch>
          <Route path='/' exact component={Home} />
          <Route path='/services' component={Services} />
          <Route path='/careers' component={CareersPage} />
          <Route path='/blog' component={Blog} />
          <Route path='/contact-us' component={ContactUs} />
          <Route path='/jmeter' component={JMeter} />
          <Route path='/loadrunner' component={LoadR} />
        </Switch>
      </Router>
    </>
  );
}

export default App;

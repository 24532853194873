import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import './Cards'
import { motion } from 'framer-motion'

function Navbar() {
  const [click, setClick] = useState(false);
  
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  const title = "AVAIME"
  const sentence = {
    hidden: {opacity: 0},
    visible: {
      opacity: 1,
      transition: {
        delay: 0.5,
        staggerChildren: 0.15,
      },
    },
  }
  const letter = {
    hidden: {opacity: 0},
    visible: {
      opacity: 1,
    },
  }

  return (
    <>
      <nav className='navbar'>
        <div className='navbar-container'>
          <motion.div
            variants={sentence}
            initial="hidden"
            animate="visible"
          >
            <Link to='/' className='navbar-logo' onClick={closeMobileMenu}>
              {title.split("").map((char, index) => {
                return (
                  <motion.span className='navbar-logo-animate' key={char + "-" + index} variants={letter}>
                    {char}
                  </motion.span>
                )
              })}
            </Link>
          </motion.div>
          <div className='menu-icon' onClick={handleClick}>
            <i className={click ? 'fas fa-times' : 'fas fa-bars'} />
          </div>
          <motion.ul className={click ? 'nav-menu active' : 'nav-menu'}
            initial={{ y: -250 }}
            animate={{ y: 0 }}
            transition={{ delay: 0.2, type: 'tween'}}
          >
            <li className='nav-item'>
              <Link to='/' className='nav-links' onClick={closeMobileMenu}>
                Home
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                to='/services'
                className='nav-links'
                onClick={closeMobileMenu}
              >
                Services
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                to='/careers'
                className='nav-links'
                onClick={closeMobileMenu}
              >
                Careers
              </Link>
            </li>
            {/* <li className='nav-item'>
              <Link
                to='/blog'
                className='nav-links'
                onClick={closeMobileMenu}
              >
                Blog
              </Link>
            </li> */}
            <li className='nav-item'>
              <Link
                to='/contact-us'
                className='nav-links'
                onClick={closeMobileMenu}
              >
              Contact
              </Link>
            </li>
          </motion.ul>
        </div>
      </nav>
    </>
  );
}

export default Navbar;

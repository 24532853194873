import React from 'react';
import '../../App.css';
import JMRTitle from '../JmtrTitle';
import JmeterCourse from '../../Training/pages/Jmtr';
import Footer from '../Footer';

export default function JMeter() {
  return (
    <>
    <JMRTitle />
    <JmeterCourse />
    <Footer />
  </>
  );
}
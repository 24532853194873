import React from 'react';
import '../../App.css';
import Careers from '../Careers';
import Opportunities from '../Opportunities';
import Footer from '../Footer';

export default function CareersPage() {
  return (
    <>
    <Careers />
    <Opportunities />
    <Footer />
  </>
  );
}

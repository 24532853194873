import React from 'react';
import '../App.css';
import './HeroSection.css';
import BackgroundSlider from 'react-background-slider';
import imagehome3 from './imagestest/image-home3.jpg';
import imagehome2 from './imagestest/image-home2.jpg';


function HeroSection() {
  return (
    <div className='hero-container'>
      <BackgroundSlider
        images={[imagehome3, imagehome2]}
        duration={3} transition={2} />
      {/* <video src='/videos/video-2.mp4' autoPlay loop muted /> */}
      {/* <h1>Cutting-Edge Technology</h1> */}
      {/* <p>What are you waiting for?</p> */}
    </div>
  );
}

export default HeroSection;

import React from 'react';
import '../App.css';
import './HeroSection.css';


function ServicesTitle() {
  return (
    <div className='career-container'>
      <h1>Services</h1>
      {/* <p>What are you waiting for?</p> */}
    </div>
  );
}

export default ServicesTitle;

import React, { useState } from 'react';
import data from '../jmeter';
import SingleQuestion from '../Loadrunnercontent';
import '../CContent.css';
function JmeterCourse() {
  const [questions] = useState(data);
  return (
    <main>
      <div className='container'>
        <section className='info'>
          {questions.map((question) => {
            return (
              <SingleQuestion key={question.id} {...question}></SingleQuestion>
            );
          })}
        </section>
      </div>
    </main>
  );
}

export default JmeterCourse;
import React from 'react';
import '../App.css';
import './HeroSection.css';
import { motion } from 'framer-motion'

function WhyUs() {
  return (
    <div className='WhyUs'>
      <h1>Who are we?</h1>
      <p>Avaime is a leading software services provider with top tech talent aboard. We support our clients and partners with wide range of services to build and implement technology solutions that deliver great community and business value. Avaimes enterprise solutions enables businesses to leverage technology to improve efficiency and effectiveness of business processes. Our recruiting team is specialized in understanding the clients requirements and assisting then in finding the right talent to help meet the short-term or long-term goals.</p>
      <br></br>
      <h1>What we do</h1>
      <div className='whatwedo-cards'>
        <ul>
          <motion.h2 className='Whatwedo' whileHover={{ scale: 1.1}} >Application Lifecycle Management</motion.h2>
          <motion.h2 className='Whatwedo' whileHover={{ scale: 1.1}} >Custom software application development</motion.h2>
        </ul>
        <ul>
          <motion.h2 className='Whatwedo' whileHover={{ scale: 1.1}} >IT Managed Services</motion.h2>
          <motion.h2 className='Whatwedo' whileHover={{ scale: 1.1}} >Cloud Transformation</motion.h2>
        </ul>
      </div>
    </div>
  );
}

export default WhyUs;

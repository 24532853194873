const questions = [
    {
      id: 1,
      title: 'Section 1: Introduction to Performance testing',
      info:
        '1. Introduction to software application testing \n2. Introduction to performance testing \n3. Types of performance testing \n4. Metrics used to determine the performance.',
    },
    {
      id: 2,
      title: 'Section 2: Introduction to Software Architecture',
      info:
        '1. Architecture and its importance for Performance testing \n2. Different types of architectures',
    },
    {
      id: 3,
      title: 'Section 3: Requirements gathering',
      info:
        '1. What are Nonfunctional Requirements (NFRs) \n2. Littles law and Workload modelling',
    },
    {
      id: 4,
      title: 'Section 4: Performance testing lifecycle',
      info:
        '1. Performance testing lifecycle \n2. Performance testing vs Performance engineering \n3. Performance test planning with a real project example',
    },
    {
      id: 5,
      title: 'Section 5: Introduction to Load Runner',
      info:
        '1. Installation of Loadrunner community edition. \n2. Architecture and Components of Loadrunner \n3. Introduction to VuGen \n4. Introduction to Controller \n5. Introduction to Analyzer',
    },
    {
      id: 6,
      title: 'Section 6: Development of test scripts using VuGen - Part 1',
      info:
        '1. Layout of VuGen \n2. Using of protocol advisor \n3. Functions used in VuGen \n4. Run-time settings \n5. Test script recording using VuGen',
    },
    {
      id: 7,
      title: 'Section 7: Development of test scripts using VuGen - Part 2',
      info:
        '1. HTML Based scripting vs Url Based scripting \n2. Addition of comments and transactions in test script \n3. Addition of check points in test script \n4. Rendezvous point',
    },
    {
      id: 8,
      title: 'Section 8: Development of test scripts using VuGen - Part 3',
      info:
        '1. Basics of C Language for test script enhancement',
    },
    {
      id: 9,
      title: 'Section 9: Development of test scripts using VuGen (Parameterization and correlation) - Part 4',
      info:
        '1. Introduction to parameterization \n2. Example test script development to perform parameterization \n3. Introduction to Correlation \n4. Basics of correlation of dynamic values \n5. Load Runner functions used for correlation. \n6. Example test script development to perform correlation',
    },
    {
      id: 10,
      title: 'Section 10: Load Runner test execution using controller',
      info:
        '1. Architecture for controller \n2. Different types of load scenarios \n3. Addition of load generators \n4. Introduction of controller rendezvous point from controller \n5. Example execution of Load/Performance test using Controller',
    },
    {
      id: 11,
      title: 'Section 11: Load Runner test result analysis using Analyzer',
      info:
        '1. Introduction to Analyzer and test result summary \n2. Understanding the detailed test report  \n3. Addition and removal of customer result graphs to test report',
    },
  ]
  export default questions
  
import React from 'react';
import './Cards.css';
import CardItem from './CardItem';

function Cards() {
  return (
    <div className='cards'>
      <br></br>
      <br></br>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
              src='images/Softdev.jpg'
              text='Custom software application development - Requirement analysis, Code, Unit test and Deployment'
              label='Software Development'
              path='/services'
            />
            <CardItem
              src='images/QA.jpg'
              text='Functional, Non-functional testing services based on clients requirements'
              label='Quality Assurance'
              path='/services'
            />
            <CardItem
              src='images/PM.jpg'
              text='Traditional and Agile lean project management methodologies to deliver better project/business value'
              label='Project Management'
              path='/services'
            />
          </ul>
          <ul className='cards__items'>
            <CardItem
              src='images/Devops.jpg'
              text='Organizational cloud transformation - Migration to stable, secure and scalable cloud services and implementation of Devops CI/CD Pipelines from scratch'
              label='Cloud Services'
              path='/services'
            />
            <CardItem
              src='images/Network.jpg'
              text='End-to-end network and Infrastructure solutions to drive business agility, productivity and efficiency'
              label='Networking/Infrastructure Services'
              path='/products'
            />
            <CardItem
              src='images/staffing.jpg'
              text='Flexible staffing solutions - Contracting, Temp-to-hire, and permanent placement for full-time jobs.'
              label='Staff Augmentation'
              path='/sign-up'
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;

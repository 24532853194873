import React from 'react';
import '../App.css';
import './HeroSection.css';


function BlogItems() {
  return (
    <div className='blogitem'>
      <h1>Article 1</h1>
      <p>We are always looking for individuals to build crossfunctional teams with diverse skillsets.</p>
      <p>We are actively looking for a right fit for the following opportunities.</p>
      <br></br>
      <h1>Article 2</h1>
      <p>We are working on getting this updated. Please check back later!</p>
      <br></br>
      <h1>Article 3</h1>
      <p>We are always looking for individuals to build crossfunctional teams with diverse skillsets.</p>
      <p>We are actively looking for a right fit for the following opportunities.</p>
      <br></br>
      <h1>Article 4</h1>
      <p>We are working on getting this updated. Please check back later!</p>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
    </div>
  );
}

export default BlogItems;

import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';

function Footer() {
  
  return (
    <div className='footer-container'>

      <section class='social-media'>
        <div class='social-media-wrap'>
          <div class='footer-logo'>
            <Link to='/' className='social-logo'>
              AVAIME
            </Link>
          </div>
          <small class='website-rights'>Avaime Inc. © 2021. All rights reserved</small>
          <div class='social-icons'>
            <Link
              class='social-icon-link facebook'
              to= {{ pathname: "https://www.facebook.com" }}
              target='_blank'
              aria-label='Facebook'
            >
              <i class='fab fa-facebook-f' />
            </Link>
            <Link
              class='social-icon-link instagram'
              to= {{ pathname: "https://www.instagram.com" }}
              target='_blank'
              aria-label='Instagram'
            >
              <i class='fab fa-instagram' />
            </Link>
            <Link
              class='social-icon-link youtube'
              to= {{ pathname: "https://www.youtube.com" }}
              target='_blank'
              aria-label='Youtube'
            >
              <i class='fab fa-youtube' />
            </Link>
            <Link
              class='social-icon-link twitter'
              to= {{ pathname: "https://www.twitter.com" }}
              target='_blank'
              aria-label='Twitter'
            >
              <i class='fab fa-twitter' />
            </Link>
            <Link
              class='social-icon-link twitter'
              to= {{ pathname: "https://www.linkedin.com" }}
              target='_blank'
              aria-label='LinkedIn'
            >
              <i class='fab fa-linkedin' />
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;

import React from 'react';
import '../../App.css';
import BlogTitle from '../BlogTitle';
import BlogItems from '../BlogItems'
import Footer from '../Footer';

export default function Products() {
  return (
    <>
    <BlogTitle />
    <BlogItems />
    <Footer />
  </>
  );
}

import React, { useState } from 'react';
import data from '../loadrunner';
import SingleQuestion from '../Loadrunnercontent';

import '../CContent.css';
function LRCourse() {
  const [questions] = useState(data);
  return (
    <main>
      <div className='container'>
        <section>
          {questions.map((question) => {
            return (
              <SingleQuestion key={question.id} {...question}></SingleQuestion>
            );
          })}
        </section>
      </div>
    </main>
  );
}

export default LRCourse;

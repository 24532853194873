import React from 'react';
import '../App.css';
import './HeroSection.css';


function Careers() {
  return (
    <div className='career-container'>
      <h1>Careers</h1>
      <h6>Time for a change?</h6>
      {/* <p>What are you waiting for?</p> */}
    </div>
  );
}

export default Careers;

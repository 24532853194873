import React from 'react';
import '../App.css';
import './HeroSection.css';


function Opportunities() {
  return (
    <div className='opportunities'>
      <h1>Current Opportunities</h1>
      <p>We are always looking for individuals to build great cross-functional teams with diverse skillsets.</p>
      <p>We are actively looking for a right fit for the following opportunities.</p>
      <br></br>
      <p>--------------------------------------------------------</p>
      <h1>Current Job listings:</h1>
      <p>We are working on getting this updated. Please check back later!</p>
      {/* <p>What are you waiting for?</p> */}
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
      <br></br>
    </div>
  );
}

export default Opportunities;

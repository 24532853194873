import React from 'react';
import '../App.css';
import './HeroSection.css';


function LRTitle() {
  return (
    <div>
        <div className='career-container'>
            <h1>Performance Testing</h1>
        </div>
        <div className='coursetitle'>
            <h2>Course Title: Performance testing with LoadRunner</h2>
            <p>Performance testing is an area of QA that focuses on testing the software applications and architecture to determine if it meets the expected speed, stability, reliability, scalability and resource consumption with a defined workload. Businesses use Performance testing to understand the application behaviour with user load and determine the key performance metrics like response times and throughput, as they impact the customer experience while visiting the application.</p>
            <h2>Course Content</h2>
            <br></br>
        </div>
    </div>
  );
}

export default LRTitle;
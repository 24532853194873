import React from 'react';
import '../../App.css';
import LRTitle from '../LRTitle';
import LRCourse from '../../Training/pages/LR';
import Footer from '../Footer';

export default function LoadR() {
  return (
    <>
    <LRTitle />
    <LRCourse />
    <Footer />
  </>
  );
}